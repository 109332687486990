import React from "react";
import pageImage from "images/HomepageLogo.png";
import styled from "styled-components";
import { Col, Row } from "reactstrap";
import { FaChevronRight } from "react-icons/fa";
import { FullBackground, Icon, Layout, PageContainer, SEO } from "components";
import { Link as GatsbyLink } from "gatsby";
import { device, routes } from "utils";

const WhyCloudLabOverviewPage = ({ location }) => {
  const title = "Advantages of a Cloud Laboratory";
  const description =
    "See how ECL enables your organization to do more with less";
  return (
    <FullBackground>
      <Layout clearNav clearFooter location={location}>
        <SEO
          image={pageImage}
          title={title}
          uri={location.pathname}
          description={description}
        />
        <MobileBanner to={routes("WCLEfficiency")}>Find out more</MobileBanner>
        <PageContainer style={{ borderTop: "1px solid #53575b" }}>
          <Heading>
            <MainTitle>Why Use the ECL</MainTitle>
            <SubHeader>
              Explore how Emerald Cloud Lab outperforms a traditional
              laboratory.
            </SubHeader>
            <StartButton to={routes("WCLEfficiency")}>
              <ActionBtn>
                Find out more <ArrowIcon />
              </ActionBtn>
            </StartButton>
          </Heading>
          <StyledRow>
            <StyledCol md={4} lg={2}>
              <StyledLink to={routes("WCLEfficiency")}>
                <StyledIcon name="EfficiencyIcon" />
              </StyledLink>
              <StepTitle>1. Efficiency</StepTitle>
              <StepDesc>
                Reduce costs and increase experimental output.
              </StepDesc>
            </StyledCol>
            <StyledCol md={4} lg={2}>
              <StyledLink to={routes("WCLFlexibilityTraditional")}>
                <StyledIcon name="FlexibilityIcon" />
              </StyledLink>
              <StepTitle>2. Flexibility</StepTitle>
              <StepDesc>
                Break free of limitations posed by instrumentation availability.
              </StepDesc>
            </StyledCol>
            <StyledCol md={4} lg={2}>
              <StyledLink to={routes("WCLProductivityTraditional")}>
                <StyledIcon name="ProductivityIcon" />
              </StyledLink>
              <StepTitle>3. Productivity</StepTitle>
              <StepDesc>
                Focus on intellectual contribution instead of manual labor.
              </StepDesc>
            </StyledCol>
            <StyledCol md={4} lg={2}>
              <StyledLink to={routes("WCLReproducibilityTraditional")}>
                <StyledIcon name="ReproducibilityIcon" />
              </StyledLink>
              <StepTitle>4. Reproducibility</StepTitle>
              <StepDesc>Repeat past work at the push of a button.</StepDesc>
            </StyledCol>
            <StyledCol md={4} lg={2}>
              <StyledLink to={routes("WCLAccessibilityTraditional")}>
                <StyledIcon name="AccessibilityIcon" />
              </StyledLink>
              <StepTitle>5. Accessibility</StepTitle>
              <StepDesc>
                All data contextualized with methods and analyses.
              </StepDesc>
            </StyledCol>
          </StyledRow>
        </PageContainer>{" "}
      </Layout>
    </FullBackground>
  );
};

export default WhyCloudLabOverviewPage;

// Styles

const MobileBanner = styled(GatsbyLink)`
  background-color: ${({ theme }) => theme.greenLink};
  color: ${({ theme }) => theme.white};
  display: block;
  font-size: 1.4rem;
  left: 0;
  line-height: 2rem;
  padding: 1rem;
  position: fixed;
  text-align: center;
  text-transform: uppercase;
  transition: all 0.25s ease;
  top: 5rem;
  width: 100%;
  z-index: 2;

  @media ${device.md} {
    display: none;
  }

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.whtie};
    text-decoration: none;
    opacity: 0.8;
  }
`;

const StyledRow = styled(Row)`
  justify-content: center;
  margin-bottom: 5rem;
  margin-top: 0;

  @media ${device.md} {
    margin-bottom: 6rem;
    margin-top: 2.6rem;
  }
`;

// TODO: The header parts matches the other two main pages.
// Move it to somewhere common
// Heading + MainTitle + SubHeader

const Heading = styled.div`
  letter-spacing: 0.07rem;
  margin-bottom: 0;
  margin-top: 10.3rem;
  text-align: center;

  @media ${device.md} {
    margin-bottom: 6rem;
    margin-top: 6.5rem;
  }
`;

const MainTitle = styled.h1`
  color: ${({ theme }) => theme.white};
  font-size: 2.8rem;
  font-weight: 500;
  line-height: 3.6rem;
  margin-bottom: 1rem;
`;

const SubHeader = styled.p`
  color: #b1bac2;
  font-size: 1.4rem;
  line-height: 2rem;
  margin-bottom: 2.5rem;
  margin-top: 0;

  @media ${device.md} {
    margin-top: 1.5rem;
  }
`;

const StartButton = styled(GatsbyLink)`
  display: none;
  transition: all 0.25s ease;

  @media ${device.md} {
    display: inline-block;
  }

  &:hover {
    opacity: 0.8;
    text-decoration: none;
  }
`;

const ActionBtn = styled.button`
  align-items: center;
  background-color: ${({ theme }) => theme.greenLink};
  border-radius: 0;
  border: none;
  color: ${({ theme }) => theme.white};
  display: flex;
  font-size: 1.2rem;
  font-weight: 500;
  height: 3.2rem;
  justify-content: center;
  margin: auto;
  outline: none;
  padding: 0 1.2rem;
  position: relative;
  text-transform: uppercase;

  &:focus {
    outline: none;
  }
`;

const ArrowIcon = styled(FaChevronRight)`
  height: 13px;
  left: 0.3rem;
  position: relative;
  top: -0.1rem;
`;

const StyledCol = styled(Col)`
  margin-bottom: 3rem;
  text-align: center;

  @media ${device.sm} {
  }

  @media ${device.lg} {
    margin-left: 1.6rem;
    margin-right: 1.6rem;
  }
`;

const StyledLink = styled(GatsbyLink)`
  cursor: pointer;
  display: inline-block;
  margin-bottom: 2rem;
  transition: all 0.5s ease;

  @media ${device.sm} {
    margin-bottom: 2.5rem;

    &:hover {
      transform: scale(1.15);
    }
  }
`;

const StepTitle = styled.h2`
  color: ${({ theme }) => theme.white};
  font-weight: 300;
  font-size: 2rem;
  margin-bottom: 0.5rem;
  white-space: nowrap;

  @media ${device.sm} {
    margin: 0 -2rem 1rem -2rem;
  }
`;

const StepDesc = styled.p`
  color: #b1bac2;
  font-size: 1.3rem;
  font-weight: 300;
  line-height: 1.8rem;
  margin-bottom: 3rem;
  margin: auto;
  max-width: 215px;

  @media ${device.md} {
    margin-bottom: 1rem;
  }
`;

const StyledIcon = styled(Icon)`
  height: 200px;
  width: 200px;
  margin-bottom: -2rem;
  margin-top: -0.5rem;

  @media ${device.sm} {
    height: 150px;
    width: 150px;
    margin: 0 -2rem;
  }
`;
